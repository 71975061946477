
import JsonView from '@uiw/react-json-view';
import { Key, useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import Button from "../../components/Button";
import CustomTable from "../../components/CustomTable";
import Input from "../../components/Input";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import StepJourney from "../../components/StepJourney";
import api from "../../service/api";
import { toastStyle } from "../../utils/toastStyles";
import { ActionsWrapper, ConsultaPropostasWrapper, ConsultaResultWrapper, ModalBodyWrapper, ModalHeaderTitle, ModalHeaderWrapper, SearchFields, SearchWrapper, Steps, Title, UserStepWrapper } from "./style";

const mock = [
    {
        user: "Proponente",
        steps: [
            {
                status: "error",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "done",
                stepName: "Seleção de Proposta"
            },
            {
                status: "done",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "done",
                stepName: "Upload de Documentos"
            },
            {
                status: "done",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "done",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Avalista 1",
        steps: [
            {
                status: "default",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "default",
                stepName: "Seleção de Proposta"
            },
            {
                status: "default",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "default",
                stepName: "Upload de Documentos"
            },
            {
                status: "default",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "default",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Avalista 2",
        steps: [
            {
                status: "default",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "default",
                stepName: "Seleção de Proposta"
            },
            {
                status: "default",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "default",
                stepName: "Upload de Documentos"
            },
            {
                status: "default",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "default",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Upload New Space",
        steps: [
            {
                status: "default",
                stepName: "Documentos Assinados"
            },
            {
                status: "default",
                stepName: "Imagens"
            }
        ]
    },
]

const download = (data: any) => {
    let dadosCorrigidos: object[] = [];

    data.forEach((item: any) => {

        let objeto: object[] = JSON.parse(item.replace(/'/g, ""));

        dadosCorrigidos.push(...objeto);
    });

    let dadosFinais: object[] = ([] as object[]).concat([...dadosCorrigidos]);

    let jsonFormatado: string = JSON.stringify(dadosFinais, null, 2);

    let blob: Blob = new Blob([jsonFormatado], { type: "application/json" });

    let url: string = window.URL.createObjectURL(blob);

    let link: HTMLAnchorElement = document.createElement("a");
    link.href = url;
    link.download = "dados.json"

    link.click();


    window.URL.revokeObjectURL(url);
}

export const ConsultaDePropostasPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openModalJson, setOpenModalJson] = useState(false);
    const [jsonSelected, setJsonSelected] = useState<any>('');
    const [downloading, setDownloading] = useState(false);

    const columnsLogins = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            {
                Header: 'Data Hora',
                accessor: 'datetime',
            },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            }
        ],
        []
    );


    const columnsSelecaoProposta = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            {
                Header: 'Data Hora',
                accessor: 'datetime',
            },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            },
            {
                Header: 'Tipo',
                accessor: 'signator_type',
            },
            {
                Header: 'Proposta',
                accessor: 'proposal',
            },
            {
                Header: 'Contrato',
                accessor: 'contract',
            },
            {
                Header: 'Filial',
                accessor: 'branch',
            },
            {
                Header: 'Loja',
                accessor: 'store',
            },
            {
                Header: 'Reinicio',
                accessor: 'restart',
            },
            {
                Header: 'Retorno da NS',
                accessor: 'newspace',
                Cell: (data: any) => {
                    return (data.row.original.newspace && data.row.original.newspace.length > 0) ? <Button leftIcon={<FiDownload />} label="Ver mais" theme="text" height="40px" onClick={() => { setJsonSelected(data.row.original.newspace); setOpenModalJson(true) }} /> : <span>Sem dados disponíveis</span>;
                }
                ,
            },
        ],
        []
    );


    const columnsLivenessBiometriaFacial = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            // {
            //     Header: 'Data Hora',
            //     accessor: 'datetime',
            // },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            },
            {
                Header: 'Proposta',
                accessor: 'proposal',
            },
            {
                Header: 'Contrato',
                accessor: 'contract',
            },
            {
                Header: 'Filial',
                accessor: 'branch',
            },
            {
                Header: 'Loja',
                accessor: 'store',
            },
            {
                Header: 'Tipo de Liveness',
                accessor: 'liveness_type',
            },
            {
                Header: 'Horário Liveness',
                accessor: 'liveness_return.datetime',
            },
            {
                Header: 'Retorno do Liveness',
                accessor: 'liveness_return.message',
            },
            {
                Header: 'Retorno da Validação',
                accessor: 'validation_return',
            },
        ],
        []
    );


    const columnsUploadDocumentos = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            // {
            //     Header: 'Data Hora',
            //     accessor: 'datetime',
            // },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            },
            {
                Header: 'Tipo',
                accessor: 'signator_type',
            },
            {
                Header: 'Proposta',
                accessor: 'proposal',
            },
            {
                Header: 'Contrato',
                accessor: 'contract',
            },
            {
                Header: 'Filial',
                accessor: 'branch',
            },
            {
                Header: 'Loja',
                accessor: 'store',
            },
            {
                Header: 'Horário Upload',
                accessor: 'upload_datetime',
            },
            {
                Header: 'Tipo doc Identidade',
                accessor: 'upload_type',
            },
            {
                Header: 'Quantidade de Arquivos',
                accessor: 'upload_quantity',
            },
            {
                Header: 'Quantidade de Arquivos (Outros)',
                accessor: 'upload_quantity_others',
            },
        ],
        []
    );

    const columnsSignDocuments = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            },
            {
                Header: 'Tipo',
                accessor: 'signator_type',
            },
            {
                Header: 'Proposta',
                accessor: 'proposal',
            },
            {
                Header: 'Contrato',
                accessor: 'contract',
            },
            {
                Header: 'Filial',
                accessor: 'branch',
            },
            {
                Header: 'Loja',
                accessor: 'store',
            },
            {
                Header: 'Termo',
                accessor: 'ter',
            },
            {
                Header: 'Custo Efetivo Total',
                accessor: 'cet',
            },
            {
                Header: 'Cédula de Cédito Bancária',
                accessor: 'ccb',
            },
            {
                Header: 'SPF',
                accessor: 'seg',
            },
            {
                Header: 'SPF PJ',
                accessor: 'spf',
            },
        ],
        []
    );

    const columnsNewSpace = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            {
                Header: 'Data Hora',
                accessor: 'datetime',
            },
            {
                Header: 'N. Documento',
                accessor: 'document',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'document_type',
            },
            {
                Header: 'Telefone Informado',
                accessor: 'phone',
            },
            {
                Header: 'Tipo',
                accessor: 'signator_type',
            },
            {
                Header: 'Proposta',
                accessor: 'proposal',
            },
            {
                Header: 'Contrato',
                accessor: 'contract',
            },
            {
                Header: 'Filial',
                accessor: 'branch',
            },
            {
                Header: 'Ação',
                accessor: 'type',
            },
            {
                Header: 'Resposta',
                accessor: 'response',
            },
        ],
        []
    );

    const columnsClciked = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'session_id',
            },
            {
                Header: 'Link clicado',
                accessor: 'counter_desc',
            },
            {
                Header: 'Horário',
                accessor: 'datetime',
            },
        ],
        []
    );



    const columnMinWidths = {};

    const [propNumber, setPropNumber] = useState("");
    const [contract, setContract] = useState("");
    const [userDocument, setUserDocument] = useState("");
    const [resultSearch, setResultSearch] = useState<any>([]);
    const [signatorId, setSignatorId] = useState<any>("");
    const [details, setDetails] = useState<any>({});

    const searchJourney = () => {
        if (propNumber.length === 8 || contract.length === 10 || userDocument.length === 11 || userDocument.length === 14) {
            toast.loading('Pesquisando proposta...', { style: toastStyle })
            setResultSearch([])
            console.log('pesquisando proposta')
            api.post(`/dashboard/v3/journey-info`, {
                proposal: propNumber,
                contract: contract,
                document: userDocument,
            }).then((response) => {
                console.log(response.data)
                setResultSearch(response.data)
                toast.dismiss();
            }).catch((error) => {
                toast.dismiss();
                toast.error('Não foi possível localizar a proposta')
                console.log(error)
            })
        }
    }

    const downloadTracking = () => {
        toast.loading('Gerando tracking...');
        setDownloading(true);
        api.get(`/dashboard/generate-tracking/${propNumber}`, {
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'tracking.pdf';
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        }).catch((error) => {
            console.log('tracking', error)
            toast.error('Ocorreu um erro ao gerar o tracking');
        }).finally(() => {
            toast.dismiss();
            setDownloading(false);
        });
    };

    useEffect(() => {
        searchJourney();
    }, [propNumber, contract, userDocument])

    useEffect(() => {
        if (signatorId && openModal) {
            toast.loading('Carregando detalhes...', { style: toastStyle })
            setDetails({})
            console.log('pesquisando proposta')
            api.get(`/dashboard/v3/${signatorId}/${propNumber ? propNumber : 'None'}/details`).then((response) => {
                console.log(response.data)
                setDetails(response.data)
                toast.dismiss();
            }).catch((error) => {
                toast.dismiss();
                toast.error('Erro ao pesquisar proposta ' + error)
                console.log(error)
            })
        }
    }, [signatorId, openModal])


    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, callback: () => void) => {
        if (e.key === 'Enter') {
            callback();
        }
    };


    const downloadAll = () => {
        const csvSections = [
            {
                title: 'Logins',
                columns: columnsLogins,
                data: details?.login,
            },
            {
                title: 'Seleção de Proposta',
                columns: columnsSelecaoProposta,
                data: details?.selecao_proposta,
            },
            {
                title: 'Liveness Biometria Facial',
                columns: columnsLivenessBiometriaFacial,
                data: details?.liveness_biometria_facial,
            },
            {
                title: 'Upload de Documentos',
                columns: columnsUploadDocumentos,
                data: details?.upload_documents,
            },
            {
                title: 'Sign Documents',
                columns: columnsSignDocuments,
                data: details?.sign_documents,
            },
            {
                title: 'New Space',
                columns: columnsNewSpace,
                data: [],
            },
        ];

        let csvContent = '';

        csvSections.forEach((section) => {
            const formatNumber = (value: any) => {
                return value?.toLocaleString('pt-BR');
            };
            csvContent += `${section.title}\n`;
            csvContent += section.columns?.map((col) => col.Header).join(';') + '\n';
            csvContent += section.data
                ?.map((row: any) =>
                    section.columns
                        ?.map((col) => {
                            const path = col.accessor.split('.');
                            let value = row;
                            for (let p of path) {
                                value = value[p];
                            }
                            const formattedValue = formatNumber(value);
                            const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
                            let encodedValue = stringValue.replace(/"/g, '""');
                            if (encodedValue?.length > 0 && encodedValue[0] === '+' || (/^-?\d+\.?\d*$/.test(encodedValue))) {
                                encodedValue = encodedValue.replace('+', '');
                                return `${encodedValue}`;
                            }
                            return ` ${encodedValue}`;
                        })
                        .join(';')
                )
                .join('\n');
            csvContent += `\n\n\n`;
        });


        const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(csvData);

        link.setAttribute('href', url);
        link.setAttribute('download', `${propNumber}.csv`);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return <PageStructureWrapper title="Propostas">
        <ConsultaPropostasWrapper>
            <SearchWrapper>
                <SearchFields>
                    <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e: any) => setPropNumber(e.target.value)} onKeyDown={(e) => handleKeyPress(e, searchJourney)} />
                    <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e: any) => setContract(e.target.value)} onKeyDown={(e) => handleKeyPress(e, searchJourney)} />
                    <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e: any) => setUserDocument(e.target.value)} onKeyDown={(e) => handleKeyPress(e, searchJourney)} />
                    {/* <Button onClick={downloadTracking} label="Tracking" theme="primary" height="40px" isLoading={downloading}/> */}
                </SearchFields>

            </SearchWrapper>
            <ConsultaResultWrapper>
                {resultSearch?.proposal?.map((item: any, index: number) => <UserStepWrapper onClick={() => {
                    if (resultSearch?.proposal?.length > 0) {
                        setSignatorId(null)
                        setSignatorId(item.signator_id)
                        setOpenModal(!openModal)
                    }
                }}>
                    <Title>{item.type}</Title>
                    <Steps>
                        {item?.steps?.map((step: any, index: number) => <StepJourney
                            key={index} status={step.status as "P" | "error" | "D"}
                            stepName={step.step} />)}
                    </Steps>
                </UserStepWrapper>)}
                {resultSearch?.proposal && <UserStepWrapper>
                    <Title>Upload New Space</Title>
                    <Steps>
                        <StepJourney status={resultSearch?.newspace?.upload_package || 'P'} stepName="Documentos Assinados" />
                        <StepJourney status={resultSearch?.newspace?.upload_complementary || 'P'} stepName="Imagens" />
                    </Steps>
                </UserStepWrapper>}

            </ConsultaResultWrapper>
        </ConsultaPropostasWrapper>
        <Modal isOpen={openModal} style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.5)' }, content: { borderRadius: '10px' } }} onAfterClose={() => setDetails({})} >
            <ModalHeaderWrapper>
                <ModalHeaderTitle>Detalhes da Proposta</ModalHeaderTitle>
                <MdClose size={20} onClick={() => setOpenModal(!openModal)} style={{ cursor: 'pointer' }} />
            </ModalHeaderWrapper>
            <ModalBodyWrapper>
                {details?.login && <CustomTable title="Logins"
                    columnMinWidths={columnMinWidths}
                    columns={columnsLogins}
                    data={details?.login} />}
                {details?.selecao_proposta && <CustomTable title="Seleção de Proposta"
                    columnMinWidths={columnMinWidths}
                    columns={columnsSelecaoProposta}
                    data={details?.selecao_proposta} />}
                {details?.liveness_biometria_facial && <CustomTable title="Liveness e Biometria Facial"
                    columnMinWidths={columnMinWidths}
                    columns={columnsLivenessBiometriaFacial}
                    data={details?.liveness_biometria_facial} />}
                {details?.upload_documents && <CustomTable title="Upload de Documentos"
                    columnMinWidths={columnMinWidths}
                    columns={columnsUploadDocumentos}
                    data={details?.upload_documents} />}
                {details?.sign_documents && <CustomTable title="Documentos Assinados"
                    columnMinWidths={columnMinWidths}
                    columns={columnsSignDocuments}
                    data={details?.sign_documents} />}
                {details?.sign_documents && <CustomTable title="New Space"
                    columnMinWidths={columnMinWidths}
                    columns={columnsNewSpace}
                    data={details?.new_space} />}
                {details?.sign_documents && <CustomTable title="Clique em Links"
                    columnMinWidths={columnMinWidths}
                    columns={columnsClciked}
                    data={details?.clicked} />}
            </ModalBodyWrapper>
            <ActionsWrapper>
                <Button leftIcon={<FiDownload />} onClick={downloadAll} label="Download" theme="primary" height="40px" disabled={!details.upload_documents} />
            </ActionsWrapper>
        </Modal>
        <Modal isOpen={openModalJson} style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.5)' }, content: { borderRadius: '10px' } }} onAfterClose={() => setJsonSelected('')} >
            <ModalHeaderWrapper>
                <ModalHeaderTitle>Detalhes do Retorno da NS</ModalHeaderTitle>
                <MdClose size={20} onClick={() => setOpenModalJson(!openModalJson)} style={{ cursor: 'pointer' }} />
            </ModalHeaderWrapper>
            <ModalBodyWrapper>
                {jsonSelected && jsonSelected?.map((jsonObject: any, index: Key | null | undefined) => (
                    <div key={index}>
                        <JsonView value={JSON.parse(jsonObject)}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}

                        />
                    </div>
                ))}
            </ModalBodyWrapper>
            <ActionsWrapper>
                <Button leftIcon={<FiDownload />} onClick={() => download(jsonSelected)} label="Download" theme="primary" height="40px" />
            </ActionsWrapper>
        </Modal>
    </PageStructureWrapper>
}